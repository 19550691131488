.select-search__options
{
    margin: 0 !important;
    padding: 0 !important;
}
.select-search {
    width: auto;
}
.pagination {
    justify-content: center;
}
.tile {
    max-width: 520px;
    margin: 0 auto;
}
.hr-primary {
    margin-top: 0;
    margin-bottom: 1rem;
    border: 0;
    border-top: 5px solid #007bff;
}
label {
    margin-top: 0.5rem;
    margin-bottom: 0.0rem;
}
.select-search__input {
    box-shadow: none;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: calc(1.5em + .75rem + 5px);
}